.contact-container {
display: block;
background: url('../img/da-contact-image.jpg');
background-size: cover;
background-position: center;
width: 60%;
margin: 40px auto;
text-align: center;
min-height: 60vh;
border-radius: 10px;
}

.form {
  max-width: 800px;
  margin: 10px auto;
}

.contact-info-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 500px;
}

.contact-form-title {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-weight: 800;
  padding-top: 20px;
}
#name {
  padding: 10px;
  width: 230px;
  border-radius: 10px;
  border: none;
  margin: 10px 0px 10px 0px;
  margin-right: 5px;
}

#email {
  padding: 10px;
  width: 230px;
  border-radius: 10px;
  border: none;
  margin: 10px 0px 10px 0px;
  margin-left: 5px;
}

#message {
  max-width: 500px;
  padding: 10px;
  resize: none;
  border: none;
  border-radius: 10px;
}

#file {
  margin-top: 10px;
}

ul {
  list-style-type: none;
}
.button {
  padding: 10px;
  border: none;
  border: 1px solid #FDBA10;
  background: none;
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  margin: 40px auto;
}

.button:hover {
  background: #e0a50f;
  cursor: pointer;
}

.contact-form-text {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  color: #f1f1f1;
  font-size: 12px;
  max-width: 50%;
  margin: 0 auto;
}

.field textarea {
  width: 100%;
}

@media screen and (max-width: 900px) {
  
  .contact-info-container{
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .contact-container {
    width: 90%;
  }

  #email {
    margin: 10px auto;
    width: 75%;
  }

  #name {
    width: 75%;
    margin: 10px auto;
  }
  

 

  #message {
    width: 75%;
    padding: 10px;
    resize: none;
    border: none;
    border-radius: 10px;
  }

  .field textarea {
    width: 100%;
    margin: 10px 0px;
  }

  .contact-form-text {
    font-size: 13px;
  }

  .button {
    margin-left: 0;
    text-align: 40px 0px;
  }
  

}