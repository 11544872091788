.container {
  background-size: cover;
  position: relative;
  width: 100%;
  max-height: 500px;
}

.container-one {
  height: 450px;
  background-size: cover;
  position: relative;
  width: 100%;
  background: url('../img/Digital-analog-hero.png');
  background-position: right;
}

.home-nav {
  position: absolute;
  width: 100%;
}

.hero-text-one {
  color: #f1f1f1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin-left: 40px;
  line-height: 12px;
  text-align: left;
}

.hero-text-one h1 {
  font-size: 23px;
  letter-spacing: 15px;
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.hero-text-one p {
  font-family: 'Gothic A1';
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 2px;
}

.hero-text {
  color: #f1f1f1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin-left: 40px;
  line-height: 12px;
  text-align: left;
}

.hero-text h3 {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
}

.video {
  width: 100%;
}

.hero-text h1 {
  font-size: 23px;
  letter-spacing: 15px;
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

.hero-text p {
  font-family: 'Gothic A1';
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 2px;
}

.content-container {
  display: flex;
  position: relative;
  background: #f1f1f1;
  height: 386px;
  width: 100%;
}



.inner-content {
  max-width: 40%;
  letter-spacing: 15px;
  margin-left: 30px;
  border-left: 3px solid #FDBA10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}




.inner-content h1 {
  padding-left: 30px;
  font-size: 20px;
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 10px;
}

.inner-content p {
  font-family: 'Gothic A1', arial, sans-serif;
  letter-spacing: 0px;
  padding-left: 30px;
  font-size: 12px;
  line-height: 30px;
  padding-bottom: 10px;
}

.content-left {
  background: url('../img/bridge.png');
  background-size: cover;
  background-position: center;
  width: 50%;
}

.content-left-two {
  background: url('../img/phone.png');
  background-size: cover;
  background-position: center;
  width: 50%;
}

.content-right {
  width: 50%;
}

.coming-soon-btn {
  border: 1px solid #f1f1f1;
  padding: 10px 20px;
  color: #f1f1f1;
  background: none;
  margin-top: 10px;
  font-family: 'Nunito sans', Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-size: 11px;
}

.coming-soon-btn a {
  text-decoration: none;
  color: #f1f1f1;
  font-family: 'Nunito sans', Arial, Helvetica, sans-serif;

}

.coming-soon-btn:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
}

.artist-container {
  display: flex;
  position: relative;
  background: #0F0F0F;
  min-height: 386px;
  width: 100%;
}

.artist-text-container {
  width: 50%;
  position: relative;
}

.artist-text {
width: 70%;
color: #f1f1f1;
border-left: 3px solid #FDBA10;
position: absolute;
top: 50%;
transform: translateY(-50%);
margin-left: 50px;
}

.artist-text h1 {
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 10px;
  margin-left: 30px;
}

.artist-text p {
  font-family: 'Gothic A1', arial, sans-serif;
  letter-spacing: 0px;
  padding-left: 30px;
  font-size: 12px;
  line-height: 30px;
}


.artist-image {
  width: 50%;
  position: relative;
}

.album-covers-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.album-covers-container img {
 max-width: 300px;
}

.home-container {
  width: 100%;
  overflow: hidden;
}

.home-btn {
  border: 1px solid #FDBA10;
  padding: 10px 20px;
  margin-left: 30px;
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  text-decoration: none;
  letter-spacing: 1px;
  width: 200px;
  font-size: 11px;
  width: 150px;
}

.home-btn-white {
  border: 1px solid #0F0F0F;
  padding: 10px 30px;
  margin-left: 30px;
  color: #0F0F0F;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-size: 11px;
  text-decoration: none;
  letter-spacing: 2px;
  width: 200px;
}

.home-btn-white:hover {
  background: #da9f0b;
  color: #f1f1f1;
  border: 1px solid #da9f0b;
}


.home-btn:hover {
  background: #da9f0b
}

.fa-spotify {
  color: #f1f1f1;
  margin-left: 20px;
  font-size: 15px;
  margin-left: 0px;
  margin-top: 10px;
}

.fa-spotify:hover {
  color: #da9f0b;
}





@media only screen and (max-width: 800px) {
  
.hero-text {
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 75%;

}

  
.hero-text-one {
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 100%;

}

.hero-text-one p {
  line-height: 20px;
  width: 80%;
  margin: 0 auto;
}

.hero-text p {
  line-height: 20px;
}
.inner-content {
  letter-spacing: 8px;
}

.inner-content p {
  line-height: 22px;
  font-size: 11px;
}

.artist-container img {
  max-width: 130px;
}

.artist-text-container {
  width: 60%;
}

.artist-text {
  width: 100%;
  margin-left: 15px;
}

.artist-text h1 {
  letter-spacing: 8px;
}

.artist-text p {
  line-height: 22px;
  font-size: 11px;
  padding-bottom: 10px;
}

.content-left {
  width: 30%;
}

.content-left-two {
  width: 30%;
}

.inner-content {
  max-width: 60%;
}

.footer-container {
  width: 90%;
  display: flex;
}


}
