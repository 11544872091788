@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hind:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'BigJohn';
  src: local('BigJohn'), url(/static/media/big-john.aa3a0087.otf);
}
#Nav {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.mobile-wide {
  padding-top: 30px;

}

.mobile-wide-container {
    z-index: 1000;
}

.links {

  display: flex;
  padding-top: 20px;
}

.links ul li a {

  color: #f1f1f1;
  font-family: 'Gothic A1', Arial, Helvetica, sans-serif;
  font-weight: 800;
  font-size: 10px;
}

.x {
  display: none;
}

.show {
  transform: translateX(150%);
  display: none;
}



.hide {
  display: none;
  visibility: hidden;
}

.res-nav-items {
  visibility: hidden;
  display: none;
}

.res-nav-items {
  display: none;
}
.burger {
  display: hidden;
  position: absolute;
}

.burger:hover {
  cursor: pointer;
}

.x {
  position: absolute;
}

.burger:hover {
  cursor: pointer;
}




.res-nav ul {
  list-style-type: none;
}

.links {
  display: flex;
}

.links ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
}

.link {
  padding-left: 50px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  font-family: "Roboto", "sans-serif";
  letter-spacing: 8px;
  font-size: 12px;
}

.link:hover {
  color: #FDBA10;
}

.branding img {
  height: 40px;
  cursor: pointer;
}

.branding {
  padding: 0;
}






@media screen and (max-width: 900px) {

  .mobile-wide-container {
    width: 100%;
    position: fixed;
    z-index: 1000;
  }

  .res-nav-items {
    display: block;
    visibility: visible;
  }


  .mobile-wide {
    display: flex;
    margin: 0 auto;
    width: 85%;
    justify-content: space-between;

 
  }

  #Nav {
    width: 80%;
    justify-content: space-around;
 
  }

  .links {
    visibility: hidden;
  }

  .links ul {
    position: static;
  }

  .burger {
    visibility: visible;
    z-index: 10000;
    display: block;
    height: 50px;
    width: 25px;

  }

  .burger img {
    height: 25px;
  }

  .show {
    z-index: 1500;
    min-height: 100vh;
    min-width: 50vw;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
    transition: all 1s ease-in-out;
    display: block;
  }

  .x {
    display: block;
  }

  .hide {
    z-index: 1500;
    min-height: 50vh;
    min-width: 50vw;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    right: 0;
    transform: translateX(150%);
    position: absolute;
    transition: all 1s ease-in-out;
    display: block;
  }


  .burger {
    position: static;
    height: 25px;
    padding-top: 10px;
  }

  .burger .line {
    border-top: 2px solid #f1f1f1;
  }

.burger:hover .line {
    border-top: 2px solid #FDBA10;
    transition: all 0.5s ease-in-out;

}

  .line {
    padding-bottom: 6px;
    border-top: 1.5px solid #000;
    transition: all 1s ease-in-out;
  }

  #Nav {
    width: 50%;
    margin: 0 auto;
  }

 


  .res-nav-items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;

  }

  .res-nav-items ul {
    list-style-type: none;
  }

  .res-nav-items ul li {
    list-style-type: none;
    margin: 15vh;
    text-align: center;
  }

  .res-nav-items a {
    text-decoration: none;
    color: #fff;
    font-family: "Gothic A1", "Helvetica" "sans-serif";
    font-weight: 400;
    letter-spacing: 10px;
    text-transform: uppercase;
    font-size: 12px;

  }
  .res-nav-items a:hover {
    color: #FDBA10;
  }
  

  .close-container:hover {
    cursor: pointer; 
    
  }

  .close-container {
    padding: 10px;
    margin-left: 20px;
  }
 

  .close-container:hover {
    cursor: pointer;
  }



  .x h1 {
    color: #f1f1f1;
    font-size: 22px;
  }




}

.container {
  background-size: cover;
  position: relative;
  width: 100%;
  max-height: 500px;
}

.container-one {
  height: 450px;
  background-size: cover;
  position: relative;
  width: 100%;
  background: url(/static/media/Digital-analog-hero.5fef14c6.png);
  background-position: right;
}

.home-nav {
  position: absolute;
  width: 100%;
}

.hero-text-one {
  color: #f1f1f1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin-left: 40px;
  line-height: 12px;
  text-align: left;
}

.hero-text-one h1 {
  font-size: 23px;
  letter-spacing: 15px;
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.hero-text-one p {
  font-family: 'Gothic A1';
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 2px;
}

.hero-text {
  color: #f1f1f1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin-left: 40px;
  line-height: 12px;
  text-align: left;
}

.hero-text h3 {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
}

.video {
  width: 100%;
}

.hero-text h1 {
  font-size: 23px;
  letter-spacing: 15px;
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

.hero-text p {
  font-family: 'Gothic A1';
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 2px;
}

.content-container {
  display: flex;
  position: relative;
  background: #f1f1f1;
  height: 386px;
  width: 100%;
}



.inner-content {
  max-width: 40%;
  letter-spacing: 15px;
  margin-left: 30px;
  border-left: 3px solid #FDBA10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}




.inner-content h1 {
  padding-left: 30px;
  font-size: 20px;
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 10px;
}

.inner-content p {
  font-family: 'Gothic A1', arial, sans-serif;
  letter-spacing: 0px;
  padding-left: 30px;
  font-size: 12px;
  line-height: 30px;
  padding-bottom: 10px;
}

.content-left {
  background: url(/static/media/bridge.d26a3b22.png);
  background-size: cover;
  background-position: center;
  width: 50%;
}

.content-left-two {
  background: url(/static/media/phone.8d0358d8.png);
  background-size: cover;
  background-position: center;
  width: 50%;
}

.content-right {
  width: 50%;
}

.coming-soon-btn {
  border: 1px solid #f1f1f1;
  padding: 10px 20px;
  color: #f1f1f1;
  background: none;
  margin-top: 10px;
  font-family: 'Nunito sans', Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-size: 11px;
}

.coming-soon-btn a {
  text-decoration: none;
  color: #f1f1f1;
  font-family: 'Nunito sans', Arial, Helvetica, sans-serif;

}

.coming-soon-btn:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
}

.artist-container {
  display: flex;
  position: relative;
  background: #0F0F0F;
  min-height: 386px;
  width: 100%;
}

.artist-text-container {
  width: 50%;
  position: relative;
}

.artist-text {
width: 70%;
color: #f1f1f1;
border-left: 3px solid #FDBA10;
position: absolute;
top: 50%;
transform: translateY(-50%);
margin-left: 50px;
}

.artist-text h1 {
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 10px;
  margin-left: 30px;
}

.artist-text p {
  font-family: 'Gothic A1', arial, sans-serif;
  letter-spacing: 0px;
  padding-left: 30px;
  font-size: 12px;
  line-height: 30px;
}


.artist-image {
  width: 50%;
  position: relative;
}

.album-covers-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.album-covers-container img {
 max-width: 300px;
}

.home-container {
  width: 100%;
  overflow: hidden;
}

.home-btn {
  border: 1px solid #FDBA10;
  padding: 10px 20px;
  margin-left: 30px;
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  text-decoration: none;
  letter-spacing: 1px;
  width: 200px;
  font-size: 11px;
  width: 150px;
}

.home-btn-white {
  border: 1px solid #0F0F0F;
  padding: 10px 30px;
  margin-left: 30px;
  color: #0F0F0F;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-size: 11px;
  text-decoration: none;
  letter-spacing: 2px;
  width: 200px;
}

.home-btn-white:hover {
  background: #da9f0b;
  color: #f1f1f1;
  border: 1px solid #da9f0b;
}


.home-btn:hover {
  background: #da9f0b
}

.fa-spotify {
  color: #f1f1f1;
  margin-left: 20px;
  font-size: 15px;
  margin-left: 0px;
  margin-top: 10px;
}

.fa-spotify:hover {
  color: #da9f0b;
}





@media only screen and (max-width: 800px) {
  
.hero-text {
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 75%;

}

  
.hero-text-one {
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 100%;

}

.hero-text-one p {
  line-height: 20px;
  width: 80%;
  margin: 0 auto;
}

.hero-text p {
  line-height: 20px;
}
.inner-content {
  letter-spacing: 8px;
}

.inner-content p {
  line-height: 22px;
  font-size: 11px;
}

.artist-container img {
  max-width: 130px;
}

.artist-text-container {
  width: 60%;
}

.artist-text {
  width: 100%;
  margin-left: 15px;
}

.artist-text h1 {
  letter-spacing: 8px;
}

.artist-text p {
  line-height: 22px;
  font-size: 11px;
  padding-bottom: 10px;
}

.content-left {
  width: 30%;
}

.content-left-two {
  width: 30%;
}

.inner-content {
  max-width: 60%;
}

.footer-container {
  width: 90%;
  display: flex;
}


}

footer {
  background: #1C1C1C;
  margin: 0;
  overflow: hidden;
  color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 0px 15px 0px;
}

footer p {
  font-family: 'Gothic A1', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 8px;
  letter-spacing: 2px;
}

.fa-instagram {
  color: #f1f1f1;
  font-size: 20px;
}

.fa-instagram:hover {
  color: #FDBA10;
  cursor: pointer;
}

.fa-facebook {
  margin-left: 20px;
  font-size: 20px;
}

.fa-facebook:hover {
  color: #FDBA10;
  cursor: pointer;
}

.email {
  display: flex;
  align-items: center;
}

.email p {
  font-weight: 800;
  padding-left: 10px;
}
body {
  background: #0F0F0F;
}
.artist-hero-container {
  height: 300px;
  background-position: center;
  background-size: cover;
  position: relative;
  background: #0F0F0F;
  overflow: hidden;
}

.artist-home-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.artist-hero-text {
  color: #f1f1f1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin-left: 40px;
}

.fa-facebook {
  color: #f1f1f1;
}

.fa-spotify {
  margin-top: 12px;
}

.fa-twitter {
  color: #f1f1f1;
  margin-left: 20px;
}

.artist-hero-text h1 {
  font-size: 23px;
  letter-spacing: 10px;
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

.artist-hero-text p {
  font-family: 'Gothic A1';
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 2px;
}

.bio-container {
  overflow: hidden;
  background: #1C1C1C;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.profile-image {
  height: 130px;
  width: 130px;
  border-radius: 500px;
  padding: 20px;
}

.bio-photo {
  display: flex;
  align-items: center;
  align-items: center;
  width: 40%;
}

.bio-photo h1 {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 6px;
}

.fa-apple {
  margin-left: 15px;
  color: #f1f1f1;
}

.fa-apple:hover {
  color: #FDBA10;
}

.fab-artist {
  font-size: 14px;
  padding: 0;
}

.fa-twitter {
  font-size: 14px;
  padding: 0;
}

.fa-soundcloud {
  color: #f1f1f1;
  margin-left: 15px;
}

.fa-soundcloud:hover {
  color: #FDBA10;
}

.v-spotify {
  margin-left: 15px;
  color: #f1f1f1;
}

.bio-text {
  max-width: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}

.bio-text h3 {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.bio-text p {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  color: #f1f1f1;
  font-size: 11px;
  line-height: 20px;
}

.about-bio-text {
  color: #f1f1f1;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
}

.artist-page-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
}


.artist-title-container h1 {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  color: #f1f1f1;
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: 800;
  text-transform: uppercase;
  border-left: 2px solid #FDBA10;
  padding-left: 10px;
}

.fa-instagram:hover {
  color: #FDBA10;
  cursor: pointer;
}



.fa-facebook:hover {
  color: #FDBA10;
  cursor: pointer;
}

.fa-twitter:hover {
  color: #FDBA10;
  cursor: pointer;
}

@media only screen and (max-width: 950px) {
  .bio-container {
    overflow: hidden;
    background: #1C1C1C;
    margin: 20px auto;
    display: block;
    justify-content: space-around;
    align-items: center;
  }

  .bio-text {
    max-width: 100%;
    padding: 0px 30px 20px 30px;
  }

  .kantra-container h1 {
    font-size: 13px;
    letter-spacing: 3px;
  }

  .profile-image {
    width: 110px;
    height: 110px;
  }

}


@media only screen and (max-width: 350px) {
  .profile-image {
    width: 80px;
    height: 80px;
  }
}
.about-container {
  width: 80%;
  background: rgb(27, 27, 27);
  margin: 40px auto 40px auto;
  padding: 20px;
}

.about-container h1 {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  letter-spacing: 5px;
}

.about-container p {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 30px;
}

.home-container {
  width: 100%;
  overflow: hidden;
}
.artist-home-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.hashtag {
  color: #f1f1f1;
  font-size: 13px;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  color:  #FDBA10;
}

.about-container h1 {
  border-left: 2px solid #FDBA10;
  padding-left: 15px;
}

.about-showcase {
  background: url(/static/media/mellay-work.b1dd2e8d.jpg);
  background-position: center;
  background-size: cover;
  height: 350px;
  margin-top: 20px;
}

@media only screen and (max-width: 950px) {
  .home-container {
    width: 100%;
    overflow: hidden;
  }
  .artist-home-container {
    width: 100vw;
    overflow: hidden;
    position: relative;
  }
}

.contact-container {
display: block;
background: url(/static/media/da-contact-image.3f3a2cb1.jpg);
background-size: cover;
background-position: center;
width: 60%;
margin: 40px auto;
text-align: center;
min-height: 60vh;
border-radius: 10px;
}

.form {
  max-width: 800px;
  margin: 10px auto;
}

.contact-info-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 500px;
}

.contact-form-title {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-weight: 800;
  padding-top: 20px;
}
#name {
  padding: 10px;
  width: 230px;
  border-radius: 10px;
  border: none;
  margin: 10px 0px 10px 0px;
  margin-right: 5px;
}

#email {
  padding: 10px;
  width: 230px;
  border-radius: 10px;
  border: none;
  margin: 10px 0px 10px 0px;
  margin-left: 5px;
}

#message {
  max-width: 500px;
  padding: 10px;
  resize: none;
  border: none;
  border-radius: 10px;
}

#file {
  margin-top: 10px;
}

ul {
  list-style-type: none;
}
.button {
  padding: 10px;
  border: none;
  border: 1px solid #FDBA10;
  background: none;
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  margin: 40px auto;
}

.button:hover {
  background: #e0a50f;
  cursor: pointer;
}

.contact-form-text {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  color: #f1f1f1;
  font-size: 12px;
  max-width: 50%;
  margin: 0 auto;
}

.field textarea {
  width: 100%;
}

@media screen and (max-width: 900px) {
  
  .contact-info-container{
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .contact-container {
    width: 90%;
  }

  #email {
    margin: 10px auto;
    width: 75%;
  }

  #name {
    width: 75%;
    margin: 10px auto;
  }
  

 

  #message {
    width: 75%;
    padding: 10px;
    resize: none;
    border: none;
    border-radius: 10px;
  }

  .field textarea {
    width: 100%;
    margin: 10px 0px;
  }

  .contact-form-text {
    font-size: 13px;
  }

  .button {
    margin-left: 0;
    text-align: 40px 0px;
  }
  

}
