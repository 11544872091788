body {
  background: #0F0F0F;
}
.artist-hero-container {
  height: 300px;
  background-position: center;
  background-size: cover;
  position: relative;
  background: #0F0F0F;
  overflow: hidden;
}

.artist-home-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.artist-hero-text {
  color: #f1f1f1;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin-left: 40px;
}

.fa-facebook {
  color: #f1f1f1;
}

.fa-spotify {
  margin-top: 12px;
}

.fa-twitter {
  color: #f1f1f1;
  margin-left: 20px;
}

.artist-hero-text h1 {
  font-size: 23px;
  letter-spacing: 10px;
  font-family: 'Hind', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

.artist-hero-text p {
  font-family: 'Gothic A1';
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 2px;
}

.bio-container {
  overflow: hidden;
  background: #1C1C1C;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.profile-image {
  height: 130px;
  width: 130px;
  border-radius: 500px;
  padding: 20px;
}

.bio-photo {
  display: flex;
  align-items: center;
  align-items: center;
  width: 40%;
}

.bio-photo h1 {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 6px;
}

.fa-apple {
  margin-left: 15px;
  color: #f1f1f1;
}

.fa-apple:hover {
  color: #FDBA10;
}

.fab-artist {
  font-size: 14px;
  padding: 0;
}

.fa-twitter {
  font-size: 14px;
  padding: 0;
}

.fa-soundcloud {
  color: #f1f1f1;
  margin-left: 15px;
}

.fa-soundcloud:hover {
  color: #FDBA10;
}

.v-spotify {
  margin-left: 15px;
  color: #f1f1f1;
}

.bio-text {
  max-width: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}

.bio-text h3 {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.bio-text p {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  color: #f1f1f1;
  font-size: 11px;
  line-height: 20px;
}

.about-bio-text {
  color: #f1f1f1;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
}

.artist-page-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
}


.artist-title-container h1 {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  color: #f1f1f1;
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: 800;
  text-transform: uppercase;
  border-left: 2px solid #FDBA10;
  padding-left: 10px;
}

.fa-instagram:hover {
  color: #FDBA10;
  cursor: pointer;
}



.fa-facebook:hover {
  color: #FDBA10;
  cursor: pointer;
}

.fa-twitter:hover {
  color: #FDBA10;
  cursor: pointer;
}

@media only screen and (max-width: 950px) {
  .bio-container {
    overflow: hidden;
    background: #1C1C1C;
    margin: 20px auto;
    display: block;
    justify-content: space-around;
    align-items: center;
  }

  .bio-text {
    max-width: 100%;
    padding: 0px 30px 20px 30px;
  }

  .kantra-container h1 {
    font-size: 13px;
    letter-spacing: 3px;
  }

  .profile-image {
    width: 110px;
    height: 110px;
  }

}


@media only screen and (max-width: 350px) {
  .profile-image {
    width: 80px;
    height: 80px;
  }
}