footer {
  background: #1C1C1C;
  margin: 0;
  overflow: hidden;
  color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 0px 15px 0px;
}

footer p {
  font-family: 'Gothic A1', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 8px;
  letter-spacing: 2px;
}

.fa-instagram {
  color: #f1f1f1;
  font-size: 20px;
}

.fa-instagram:hover {
  color: #FDBA10;
  cursor: pointer;
}

.fa-facebook {
  margin-left: 20px;
  font-size: 20px;
}

.fa-facebook:hover {
  color: #FDBA10;
  cursor: pointer;
}

.email {
  display: flex;
  align-items: center;
}

.email p {
  font-weight: 800;
  padding-left: 10px;
}