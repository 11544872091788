.about-container {
  width: 80%;
  background: rgb(27, 27, 27);
  margin: 40px auto 40px auto;
  padding: 20px;
}

.about-container h1 {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  letter-spacing: 5px;
}

.about-container p {
  color: #f1f1f1;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 30px;
}

.home-container {
  width: 100%;
  overflow: hidden;
}
.artist-home-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.hashtag {
  color: #f1f1f1;
  font-size: 13px;
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  color:  #FDBA10;
}

.about-container h1 {
  border-left: 2px solid #FDBA10;
  padding-left: 15px;
}

.about-showcase {
  background: url('../img/mellay-work.jpg');
  background-position: center;
  background-size: cover;
  height: 350px;
  margin-top: 20px;
}

@media only screen and (max-width: 950px) {
  .home-container {
    width: 100%;
    overflow: hidden;
  }
  .artist-home-container {
    width: 100vw;
    overflow: hidden;
    position: relative;
  }
}
