@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;600;700&display=swap');



#Nav {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.mobile-wide {
  padding-top: 30px;

}

.mobile-wide-container {
    z-index: 1000;
}

.links {

  display: flex;
  padding-top: 20px;
}

.links ul li a {

  color: #f1f1f1;
  font-family: 'Gothic A1', Arial, Helvetica, sans-serif;
  font-weight: 800;
  font-size: 10px;
}

.x {
  display: none;
}

.show {
  transform: translateX(150%);
  display: none;
}



.hide {
  display: none;
  visibility: hidden;
}

.res-nav-items {
  visibility: hidden;
  display: none;
}

.res-nav-items {
  display: none;
}
.burger {
  display: hidden;
  position: absolute;
}

.burger:hover {
  cursor: pointer;
}

.x {
  position: absolute;
}

.burger:hover {
  cursor: pointer;
}




.res-nav ul {
  list-style-type: none;
}

.links {
  display: flex;
}

.links ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
}

.link {
  padding-left: 50px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  font-family: "Roboto", "sans-serif";
  letter-spacing: 8px;
  font-size: 12px;
}

.link:hover {
  color: #FDBA10;
}

.branding img {
  height: 40px;
  cursor: pointer;
}

.branding {
  padding: 0;
}






@media screen and (max-width: 900px) {

  .mobile-wide-container {
    width: 100%;
    position: fixed;
    z-index: 1000;
  }

  .res-nav-items {
    display: block;
    visibility: visible;
  }


  .mobile-wide {
    display: flex;
    margin: 0 auto;
    width: 85%;
    justify-content: space-between;

 
  }

  #Nav {
    width: 80%;
    justify-content: space-around;
 
  }

  .links {
    visibility: hidden;
  }

  .links ul {
    position: static;
  }

  .burger {
    visibility: visible;
    z-index: 10000;
    display: block;
    height: 50px;
    width: 25px;

  }

  .burger img {
    height: 25px;
  }

  .show {
    z-index: 1500;
    min-height: 100vh;
    min-width: 50vw;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
    transition: all 1s ease-in-out;
    display: block;
  }

  .x {
    display: block;
  }

  .hide {
    z-index: 1500;
    min-height: 50vh;
    min-width: 50vw;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    right: 0;
    transform: translateX(150%);
    position: absolute;
    transition: all 1s ease-in-out;
    display: block;
  }


  .burger {
    position: static;
    height: 25px;
    padding-top: 10px;
  }

  .burger .line {
    border-top: 2px solid #f1f1f1;
  }

.burger:hover .line {
    border-top: 2px solid #FDBA10;
    transition: all 0.5s ease-in-out;

}

  .line {
    padding-bottom: 6px;
    border-top: 1.5px solid #000;
    transition: all 1s ease-in-out;
  }

  #Nav {
    width: 50%;
    margin: 0 auto;
  }

 


  .res-nav-items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;

  }

  .res-nav-items ul {
    list-style-type: none;
  }

  .res-nav-items ul li {
    list-style-type: none;
    margin: 15vh;
    text-align: center;
  }

  .res-nav-items a {
    text-decoration: none;
    color: #fff;
    font-family: "Gothic A1", "Helvetica" "sans-serif";
    font-weight: 400;
    letter-spacing: 10px;
    text-transform: uppercase;
    font-size: 12px;

  }
  .res-nav-items a:hover {
    color: #FDBA10;
  }
  

  .close-container:hover {
    cursor: pointer; 
    
  }

  .close-container {
    padding: 10px;
    margin-left: 20px;
  }
 

  .close-container:hover {
    cursor: pointer;
  }



  .x h1 {
    color: #f1f1f1;
    font-size: 22px;
  }




}
